import { Link } from 'react-router-dom';
import s3image1 from '../assets/s3image1.jpg';

function Section3() {
  return (
    <div className="lg:block h-[675px] md:h-[575px] lg:h-[65vh] text-gray-800 bg-gray-100 relative overflow-auto">
      <div className="absolute right-0 top-0">
        <div className="hidden lg:block bg-orange-400 h-80 w-96 z-0"></div>
      </div>
      <div>
        <p
          id="About"
          className="font-black text-4xl text-center pt-8 md:pt-10 mb-3"
        >
          ABOUT <span className="text-orange-400">US</span>
        </p>
        <hr className="border-2 border-orange-300 mx-auto w-16 rounded-md" />
      </div>
      <div className="flex mt-8 md:mt-10 justify-center">
        <div className="w-3/4 lg:w-2/5 h-full lg:mr-20 text-base md:text-lg text-center">
          <p>
            With over 30 years of experience there is almost nothing we can not
            do. We are a licenced team. We are a local handyman company in
            Jacksonville Florida. Our team has serviced thousands of families.
            We are home repair and improvement experts, and we are known for the
            quality of our workmanship and professional reliability so you know
            the job will be done right correctly and efficently. We make sure we
            get the job done right the first time. We take pride in the work we
            do. Our attention to detail is what makes us different. Super Handy
            Home is your one-call solution for a wide range of home maintenance
            and repair needs.
          </p>
        </div>
        <img
          src={s3image1}
          alt="HVAC Maintenance"
          className="h-72 w-1/3 rounded-3xl z-10 hidden lg:inline"
        />
      </div>
      <div className="pt-8 md:pt-12 lg:pt-8 flex justify-center lg:block">
        <Link
          to="/Plans"
          className="lg:ml-[355px] mx-auto bg-orange-400 rounded-full font-black text-white px-6 py-4 text-base md:text-lg shadow-orange-200 shadow-lg hover:bg-orange-500 hover:text-xl hover:shadow-xl"
        >
          Service Plans
        </Link>
      </div>
    </div>
  );
}

export default Section3;
