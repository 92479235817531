import { Link } from 'react-router-dom';
import s2image1 from '../assets/s2image1.jpeg';
import s2image2 from '../assets/s2image2.jpg';
import s2image3 from '../assets/s2image3.jpg';
import s2image4 from '../assets/s2image4.jpg';
import s2image5 from '../assets/s2image5.png';
import s2image6 from '../assets/s2image6.png';
import s2image7 from '../assets/s2image7.webp';
import s2image8 from '../assets/s2image8.jpeg';

function Section2() {
  return (
    <div className="lg:block h-[771px] md:h-[1000px] lg:h-[95vh] text-gray-800">
      <div>
        <p className="font-black text-4xl text-center mt-8 md:mt-10 mb-3">
          HOW <span className="text-orange-400">WE WORK</span>
        </p>
        <hr className="border-2 border-orange-300 mx-auto w-16 rounded-md" />
      </div>
      <div className="grid grid-cols-2 grid-rows-4 lg:grid-cols-4 lg:grid-rows-2 gap-4 mx-6 md:mx-20 mt-6 md:mt-10">
        <img
          src={s2image1}
          alt="home maintenance"
          className="rounded-xl lg:rounded-3xl h-24 md:h-36 lg:h-52 w-full"
        />
        <img
          src={s2image2}
          alt="home maintenance"
          className="rounded-xl lg:rounded-3xl h-24 md:h-36 lg:h-52 w-full"
        />
        <img
          src={s2image3}
          alt="home maintenance"
          className="rounded-xl lg:rounded-3xl h-24 md:h-36 lg:h-52 w-full"
        />
        <img
          src={s2image4}
          alt="home maintenance"
          className="rounded-xl lg:rounded-3xl h-24 md:h-36 lg:h-52 w-full"
        />
        <img
          src={s2image5}
          alt="home maintenance"
          className="rounded-xl lg:rounded-3xl h-24 md:h-36 lg:h-52 w-full"
        />
        <img
          src={s2image6}
          alt="home maintenance"
          className="rounded-xl lg:rounded-3xl h-24 md:h-36 lg:h-52 w-full"
        />
        <img
          src={s2image7}
          alt="home maintenance"
          className="rounded-xl lg:rounded-3xl h-24 md:h-36 lg:h-52 w-full"
        />
        <img
          src={s2image8}
          alt="home maintenance"
          className="rounded-xl lg:rounded-3xl h-24 md:h-36 lg:h-52 w-full"
        />
      </div>
      <div className="flex flex-row justify-center mt-6">
        <p className="hidden font-black lg:text-4xl lg:flex items-center">
          WE KNOW HOW
        </p>
        <div className="h-20 md:h-16 border-orange-400 border-l-4 rounded-xl mt-1 mx-4"></div>
        <p className="w-5/6 md:w-3/4 lg:w-2/3 text-xs md:text-sm">
          We believe that providing excellent customer service is a matter of
          being reliable, avaliable, and easy to work with. Our job is to
          provide you with an easy, seamless experience. We ensure that every
          job is completed to the highest standards, and that you are 100%
          satisfied. If for any reason you are not, we will return and make it
          right.
        </p>
      </div>
      <div className="flex justify-center mt-8 mb-6 md:mt-10 md:mb-10">
        <Link
          to="/Plans"
          className="bg-orange-400 rounded-full font-black text-white px-6 py-4 text-sm md:text-lg shadow-orange-200 shadow-lg hover:bg-orange-500 hover:text-xl hover:shadow-xl"
        >
          Service Plans
        </Link>
      </div>
    </div>
  );
}

export default Section2;
