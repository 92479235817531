import Navbar from '../components/Navbar';
import Section2 from '../components/Section2';
import Section3 from '../components/Section3';
import Section4 from '../components/Section4';
import Section5 from '../components/Section5';
import Footer from '../components/Footer';

function Home() {
  return (
    <div className="w-full">
      <Navbar />
      <div className="h-screen w-full bg-landingimage bg-cover flex items-center justify-center space-x-20 overflow-auto">
        <div className="bg-white h-[550px] max-[320px]:w-5/6 max-[320px]:h-5/6 md:h-[600px] w-3/4 min-[420px]:w-[320px] md:w-1/2 lg:w-1/3 mt-10 lg:mt-20 rounded-3xl shadow-[#ffa500] shadow-lg text-center text-gray-800 overflow-auto">
          <p className="font-black text-[30px] min-[420px]:text-[40px] mb-2 mt-4 leading-normal xl:leading-tight">
            HOME <br /> MAINTENANCE <br />
            MADE <span className="text-orange-400">EASY</span>
          </p>
          <hr className="border-2 border-orange-300 mx-20 rounded-md" />
          <br />
          <p className="mx-6 xl:mx-6 text-xs xl:text-base md:text-sm">
            Did you know more than
            <span className="font-black"> 15,000 fires</span> every year are
            caused by build-up in dryer exhaust systems, and sump pump failures
            are responsible for more
            <span className="font-black"> water damage</span> than any other
            cause?
          </p>
          <p className="mx-6 mt-4 xl:mx-6 text-xs xl:text-base md:text-sm">
            Ignoring routine home maintenance tasks not only shortens the life
            of your home systems, it can cause
            <span className="font-black"> devastating damage </span>
            and <span className="font-black"> costly repairs</span>.
          </p>
          <p className="mx-6 mt-4 xl:mx-6 text-xs xl:text-base md:text-sm">
            Super Handy Home's maintenance subscription service takes care of
            your ongoing and seasonal home maintenance tasks, so you can enjoy
            <span className="font-black"> peace of mind</span> all year long.
          </p>
          <p className="mx-6 mt-4 xl:mx-6 text-xs xl:text-base md:text-sm">
            Performing regular home maintenance{' '}
            <span className="font-black">
              helps extend the life of things like your HVAC system and
              appliances
            </span>
            . This can help you save tens of thousands of dollars on premature
            replacement and repair bills!
          </p>
        </div>
        <div className="hidden lg:block bg-hero bg-contain bg-no-repeat bg-cover h-3/4 w-2/5 mt-20"></div>
      </div>
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Footer />
    </div>
  );
}

export default Home;
