import Navbar from '../components/Navbar';
import Footer2 from '../components/Footer2';
import check from '../assets/check.png';
import { HashLink } from 'react-router-hash-link';

function Plans() {
  return (
    <div className="h-[1950px] lg:h-screen overflow-auto">
      <Navbar />
      <div className="h-full bg-gray-100">
        <p className="font-black text-4xl text-center pt-24 mb-3">
          SAY GOODBYE TO YOUR <br></br>
          <span className="text-orange-400">TO-DO</span> LIST !
        </p>
        <hr className="border-2 border-orange-300 rounded-md mb-8 mx-auto w-40" />
        <div className="flex flex-col lg:flex-row justify-center items-center">
          <div className="h-[520px] lg:h-[500px] w-[320px] md:w-[500px] lg:w-[380px] bg-white rounded-3xl lg:mr-20 shadow-[#ffa500] shadow-lg">
            <p className="text-center font-bold text-4xl mt-6">
              Basic <span className="text-orange-400">Plan</span>
            </p>
            <p className="text-center mt-10 text-3xl">
              <span className="text-5xl font-black">$149</span>/mo
            </p>
            <div className="mt-8 ml-10">
              <div className="flex items-center">
                <img
                  src={check}
                  alt="check"
                  className="h-[40px] w-[40px] mb-4"
                />
                <p className="ml-4 mb-2 mr-2">
                  2 Hours of free maintenance a month, Additional time is $70 an
                  hour
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={check}
                  alt="check"
                  className="h-[40px] w-[40px] mb-4"
                />
                <p className="ml-4 mb-4 text-md">HVAC Service</p>
              </div>
              <div className="flex items-center">
                <img
                  src={check}
                  alt="check"
                  className="h-[40px] w-[40px] mb-4"
                />
                <p className="ml-4 mb-4">Pool Cleaning</p>
              </div>
              <div className="flex items-center">
                <img
                  src={check}
                  alt="check"
                  className="h-[40px] w-[40px] mb-4"
                />
                <p className="ml-4 mb-4">24/7 Emergency Call</p>
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <HashLink
                to="/#Contact Us"
                className="hash bg-orange-400 rounded-full text-white font-black text-lg py-2 px-8 shadow-lg hover:bg-orange-500"
              >
                Basic Plan
              </HashLink>
            </div>
          </div>
          <div className="h-[520px] lg:h-[500px] w-[320px] md:w-[500px] lg:w-[380px] bg-white rounded-3xl mt-10 lg:mt-0 lg:mr-20 shadow-[#ffa500] shadow-lg">
            <p className="text-center font-bold text-4xl mt-6">
              Pro <span className="text-orange-400">Plan</span>
            </p>
            <p className="text-center mt-10 text-3xl">
              <span className="text-5xl font-black">$249</span>/mo
            </p>
            <div className="mt-8 ml-10">
              <div className="flex items-center">
                <img
                  src={check}
                  alt="check"
                  className="h-[40px] w-[40px] mb-4"
                />
                <p className="ml-4 mb-2 mr-2">
                  4 Hours of free maintenance a month, Additional time is $60 an
                  hour
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={check}
                  alt="check"
                  className="h-[40px] w-[40px] mb-4"
                />
                <p className="ml-4 mb-4">1 Pressure wash</p>
              </div>
              <div className="flex items-center">
                <img
                  src={check}
                  alt="check"
                  className="h-[40px] w-[40px] mb-4"
                />
                <p className="ml-4 mb-4">
                  Quarterly interior & exterior inspection
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={check}
                  alt="check"
                  className="h-[40px] w-[40px] mb-4"
                />
                <p className="ml-4 mb-4">24/7 Emergency calls</p>
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <HashLink
                to="/#Contact Us"
                className="hash bg-orange-400 rounded-full text-white font-black text-lg py-2 px-8 shadow-lg hover:bg-orange-500"
              >
                Pro Plan
              </HashLink>
            </div>
          </div>
          <div className="h-[520px] lg:h-[500px] w-[320px] md:w-[500px] lg:w-[380px] bg-white rounded-3xl mt-10 lg:mt-0 shadow-[#ffa500] shadow-lg">
            <p className="text-center font-bold text-4xl mt-6">
              Super <span className="text-orange-400">Plan</span>
            </p>
            <p className="text-center mt-10 text-3xl">
              <span className="text-5xl font-black">$499</span>/mo
            </p>
            <div className="mt-8 ml-10">
              <div className="flex items-center">
                <img
                  src={check}
                  alt="check"
                  className="h-[40px] w-[40px] mb-4"
                />
                <p className="ml-4 mb-2 mr-2">
                  7 Hours of free maintenance a month, Additional time is $50 an
                  hour
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={check}
                  alt="check"
                  className="h-[40px] w-[40px] mb-4"
                />
                <p className="ml-4 mb-4">2 Pressure washes a year</p>
              </div>
              <div className="flex items-center">
                <img
                  src={check}
                  alt="check"
                  className="h-[40px] w-[40px] mb-4"
                />
                <p className="ml-4 mb-4">Gutter Cleaning</p>
              </div>
              <div className="flex items-center">
                <img
                  src={check}
                  alt="check"
                  className="h-[40px] w-[40px] mb-4"
                />
                <p className="ml-4 mb-4">24/7 Emergency calls</p>
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <HashLink
                to="/#Contact Us"
                className="hash bg-orange-400 rounded-full text-white font-black text-lg py-2 px-8 shadow-lg hover:bg-orange-500"
              >
                Super Plan
              </HashLink>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </div>
  );
}

export default Plans;
