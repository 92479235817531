function Footer() {
  return (
    <div className="h-20 bg-orange-400 flex justify-center items-center">
      <p className="text-center text-white text-sm">
        2022 SUPER HANDY HOME. ALL RIGHTS RESERVED.
      </p>
    </div>
  );
}

export default Footer;
