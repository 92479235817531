import fivestar from '../assets/fivestar.png';
import jaxmap from '../assets/jaxmap.png';
import mail from '../assets/mail.png';
import phone from '../assets/phone.png';
import pin from '../assets/pin.png';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { useEffect } from 'react';

function Section5() {
  let templateParams = {
    name: 'Null',
    email: 'Null',
    phone: 'Null',
    plan: 'Null',
  };

  useEffect(() => {
    let form = document.getElementById('form');
    console.log('Hello');

    form.addEventListener('submit', e => {
      e.preventDefault();
      templateParams.name = document.getElementById('name').value;
      templateParams.phone = document.getElementById('phone').value;
      templateParams.plan = document.getElementById('plan').value;
      templateParams.email = document.getElementById('email').value;

      emailjs
        .send(
          'service_q1jnn7b',
          'contact_form',
          templateParams,
          'e-Amc5fcYv-XiUTka'
        )
        .then(
          response => {
            console.log('SUCCESS!', response.status, response.text);
          },
          err => {
            console.log('FAILED...', err);
          }
        );

      window.scrollTo(0, 0);
    });
  }, []);

  return (
    <div className="h-[2200px] lg:h-[1250px] text-gray-800 bg-gray-100 relative">
      <div className="hidden lg:block absolute right-0 top-0">
        <div className="bg-orange-400 h-[40rem] w-[30rem] z-0 rounded-l-full"></div>
      </div>
      <div>
        <p id="About" className="font-black text-4xl text-center pt-10 mb-3">
          TESTIMONIALS
        </p>
        <hr className="border-2 border-orange-300 w-20 mx-auto rounded-md" />
      </div>
      <div className="flex flex-col lg:flex-row w-full h-[1100px] lg:h-[350px] mt-8 lg:mt-14">
        <div className="bg-white rounded-3xl w-[300px] lg:w-[350px] h-[320px] lg:h-[350px] mx-auto lg:ml-32 2xl:ml-80 p-5 shadow-[#ffa500] shadow-lg overflow-auto">
          <p className="font-bold text-xl mb-5">Mrs. Crews</p>
          <p className="text-gray-500 text-sm">
            "Super Handy Home's monthly home maintenance service has made my
            life so much easier. I am 79 and keeping my home up is very
            important to me. With Super Handy Home, I make a list each month of
            items that need attention and I know Chesku will take care of them.
            The monthly service has given me tremendous peace of mind."
          </p>
          <img
            src={fivestar}
            alt="five stars"
            className="w-28 h-5 mt-[2rem] lg:mt-[4.8rem]"
          />
        </div>
        <div className="bg-white rounded-3xl w-[300px] lg:w-[350px] h-[390px] lg:h-[350px] mx-auto lg:mx-8 p-5 mt-8 lg:mt-0 shadow-[#ffa500] shadow-lg overflow-auto z-10">
          <p className="font-bold text-xl mb-5">Mr. Snedden</p>
          <p className="text-gray-500 text-sm">
            "Super Handy Home's work is flawless. From installation of shiplap,
            a new fireplace surround and a kitchen backsplash to full remodeling
            our screen porch. They always bring great ideas and excellent
            skills. The team is smart, timely, dedicated, accessible, and
            meticulous. They are also very creative and offer ideas on the best
            way to create the look you desire in your project, all while being
            budget-minded. Super Handy Home has been an invaluable find for us!"
          </p>
          <img src={fivestar} alt="five stars" className="w-28 h-5 mt-4" />
        </div>
        <div className="bg-white rounded-3xl w-[300px] lg:w-[350px] h-[300px] lg:h-[350px] mx-auto lg:mr-32 2xl:mr-80 z-10 p-5 mt-8 lg:mt-0 shadow-[#ffa500] shadow-lg overflow-auto">
          <p className="font-bold text-xl mb-5">Mrs. Jones</p>
          <p className="text-gray-500 text-sm">
            "Super Handy Home recently did some interior work on our new home
            and did a superb job. We are very happy with the results and
            customer service we received. Chesku was precise, professional, and
            very attentive. We highly recommend him and his services!"
          </p>
          <img
            src={fivestar}
            alt="five stars"
            className="w-28 h-5 mt-[2rem] lg:mt-[7.25rem]"
          />
        </div>
      </div>
      <div className="flex justify-center mt-10">
        <Link
          to="/Plans"
          className="bg-white rounded-full font-black text-orange-400 border-[3px] border-[#FFA500] px-6 py-4 text-lg shadow-orange-200 shadow-lg hover:bg-orange-500 hover:text-xl hover:shadow-xl hover:text-white hover:border-orange-500 hover:border-4"
        >
          I NEED HELP
        </Link>
      </div>
      <div className="flex flex-col lg:flex-row bg-gray-200 mt-20 w-[300px] md:w-[500px] lg:w-[1200px] h-[725px] lg:h-[460px] z-30 rounded-3xl mx-auto">
        <img
          src={jaxmap}
          alt="service area"
          className="w-[420px] h-[460px] rounded-l-3xl hidden lg:inline"
        />
        <div className="w-[280px] md:w-full lg:w-[450px]">
          <p
            id="Contact Us"
            className="font-black text-4xl pt-6 mb-3 ml-4 md:mx-auto md:flex md:justify-center lg:block lg:ml-14"
          >
            CONTACT US
          </p>
          <hr className="border-2 border-orange-300 rounded-md w-[70px] ml-4 md:mx-auto lg:ml-14" />
          <div className="ml-4 md:mx-auto lg:mx-0 md:flex md:flex-col md:items-center lg:block lg:ml-14 lg:w-[380px]">
            <form id="form" className="flex flex-col">
              <input
                type="text"
                id="name"
                name="name"
                className="w-[260px] md:w-[320px] h-[50px] mt-8 rounded-lg indent-3"
                placeholder="Name"
              />
              <input
                type="email"
                id="email"
                name="email"
                className="w-[260px] md:w-[320px] h-[50px] mt-4 rounded-lg indent-3"
                placeholder="E-mail"
              />
              <input
                type="text"
                id="phone"
                name="phone"
                className="w-[260px] md:w-[320px] h-[50px] mt-4 rounded-lg indent-3"
                placeholder="Phone Number"
              />
              <select
                id="plan"
                name="plan"
                className="w-[260px] md:w-[320px] h-[50px] mt-4 rounded-lg indent-3"
                placeholder="Service Plan"
              >
                <option value="Basic Plan">Basic Plan</option>
                <option value="Pro Plan">Pro Plan</option>
                <option value="Super Plan">Super Plan</option>
                <option value="Undecided">Undecided</option>
              </select>
              <input
                type="submit"
                className="w-[160px] h-[50px] mt-8 rounded-full bg-orange-400 text-white font-semibold hover:bg-orange-500 lg:ml-20 mx-auto lg:mx-0"
                value="Submit"
              />
            </form>
          </div>
        </div>
        <div className="flex flex-col justify-start mt-10 lg:block w-full lg:w-[100px] lg:w-[350px] h-full lg:mt-[100px] lg:mr-24">
          <div className="flex items-center justify-center lg:justify-start mt-4 lg:mt-8 mx-auto lg:mx-0 w-full lg:w-[200px]">
            <img src={mail} alt="mail" className="h-[30px] w-[30px]" />
            <p className="ml-4">Superhandyhome@gmail.com</p>
          </div>
          <div className="flex items-center justify-center lg:justify-start mt-4 lg:mt-8 mx-auto lg:mx-0 w-full lg:w-[200px]">
            <img src={phone} alt="phone" className="h-[30px] w-[30px]" />
            <p className="ml-4">(904)-299-9624</p>
          </div>
          <div className="flex items-center justify-center lg:justify-start mt-4 lg:mt-8 mx-auto lg:mx-0 w-full lg:w-[200px]">
            <img src={pin} alt="pin" className="h-[30px] w-[30px]" />
            <p className="ml-4">Jacksonville, Florida</p>
          </div>
          <p className="text-center font-semibold mt-8 lg:mt-[6.7rem] text-sm lg:hidden">
            Thank you for contacting us! <br></br> We will be in touch soon.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Section5;
