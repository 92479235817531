import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import MobileNav from './pages/MobileNav';
import Plans from './pages/Plans';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/mobile" element={<MobileNav />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
