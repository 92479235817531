import { HashLink } from 'react-router-hash-link';

function MobileNav() {
  return (
    <div className="h-screen w-full text-gray-800 flex flex-col justify-center items-center font-black space-y-10 bg-cover bg-no-repeat bg-landingimage">
      <HashLink
        to="/"
        className="text-4xl md:text-5xl bg-white rounded-full py-3 px-5 md:py-5 md:px-8 shadow-[#ffa500] shadow-lg"
      >
        Home
      </HashLink>
      <HashLink
        to="/#About"
        className="text-4xl md:text-5xl bg-white rounded-full py-3 px-5 md:py-5 md:px-8 shadow-[#ffa500] shadow-lg"
      >
        About
      </HashLink>
      <HashLink
        to="/plans"
        className="text-4xl md:text-5xl bg-white rounded-full py-3 px-3 md:py-5 md:px-8 shadow-[#ffa500] shadow-lg text-center"
        decoration-orange-400
      >
        Subscription Plans
      </HashLink>
      <HashLink
        to="/#Services"
        className="text-4xl md:text-5xl bg-white rounded-full py-3 px-5 md:py-5 md:px-8 shadow-[#ffa500] shadow-lg"
      >
        Services
      </HashLink>
      <HashLink
        to="/#Contact Us"
        className="text-4xl md:text-5xl bg-white rounded-full py-3 px-5 md:py-5 md:px-8 shadow-[#ffa500] shadow-lg"
      >
        Contact Us
      </HashLink>
    </div>
  );
}

export default MobileNav;
