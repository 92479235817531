import s4image1 from '../assets/s4image1.jpg';
import s4image2 from '../assets/s4image2.jpg';
import s4image3 from '../assets/s4image3.jpg';
import check from '../assets/check.png';

function Section4() {
  return (
    <div className="h-[1150px] lg:h-[1200px] text-gray-800 overflow-auto">
      <div>
        <p id="Services" className="font-black text-4xl text-center pt-6 mb-3">
          SERVICES
        </p>
        <hr className="border-2 border-orange-300 mx-auto w-16 rounded-md" />
      </div>
      <div className="grid gap-2 lg:gap-6 grid-cols-1 grid-rows-3 lg:grid-cols-2 lg:grid-rows-3 h-[1000px] mt-8 lg:mt-12">
        <div className="hidden lg:flex justify-center">
          <img
            className="hidden lg:inline h-full w-4/5 rounded-3xl ml-10"
            src={s4image1}
            alt="home maintenance"
          />
        </div>
        <div className="grid gap-1 lg:gap-3 grid-cols-2 grid-rows-5 mr-0 lg:mr-10">
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              PAINT TOUCH UP
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              GUTTER REPAIR & CLEANING
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              GENERAL MAINTENANCE
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              ADJUST & LUBE EXTERIOR DOORS & LOCKS
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              INSTALL INSULATION
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              WINDOW A/C INSTALLATION
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              SIDING & TRIM CAULK AND REPAIR
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              PLUMBING FIXTURE CHECK
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              CLEAN DRYER VENT
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              MINOR WALL/CEILING REPAIRS
            </p>
          </div>
        </div>
        <div className="grid gap-1 lg:gap-3 grid-cols-2 grid-rows-5 ml-0 lg:ml-20">
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              POWER WASH EXTERIOR
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              MOVE FURNITURE
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              INSTALL ANTI-INTRUSION DEVICES
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              WEATHER STRIP DOORS & WINDOWS
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              FENCE & GATE REPAIR & ADJUSTMENT
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              REPAIR DECKS, STOOPS, AND PERGOLAS
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              WHOLE HOUSE INSPECTION
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              POWER WASH & SEAL DECKS
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              CAULK BATHROOM & KITCHEN
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              TEST ELECTRICAL SAFETY DEVICES
            </p>
          </div>
        </div>
        <div className="hidden lg:flex justify-center">
          <img
            className="hidden lg:inline h-full w-5/6 rounded-3xl mr-14"
            src={s4image2}
            alt="home maintenance"
          />
        </div>
        <div className="hidden lg:flex justify-center">
          <img
            className="hidden lg:inline h-full w-4/5 rounded-3xl ml-10"
            src={s4image3}
            alt="home maintenance"
          />
        </div>
        <div className="grid gap-1 lg:gap-3 grid-cols-2 grid-rows-5 mr-0 lg:mr-10">
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              BULBS/SMOKE/CO DETECTORS
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              INSTALL HANDICAP ACCESSORIES
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              DRAIN/CLEAN WATER HEATER
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              PUT UP LAWN FURNITURE & GRILL
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              TRIM OR ADJUST DOORS
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              SUMP PUMP INSPECTION
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              YARD CLEANUP
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              CARPENTRY
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              GUTTER REPAIR & CLEANING
            </p>
          </div>
          <div className="flex font-semibold items-center ml-4 md:ml-10 lg:ml-0">
            <img className="h-6 w-8 md:h-8 md:w-10" src={check} alt="check" />
            <p className="text-xs md:text-base lg:text-lg ml-2 lg:ml-6">
              VACUUM REFRIGERATOR COILS
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section4;
