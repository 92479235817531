import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Navbar() {
  return (
    <div className="font-poppins bg-black flex justify-between items-center h-16 md:h-20 absolute top-0 w-full">
      <div className="flex justify-around md:justify-between lg:flex text-orange-400 text-2xl md:text-3xl lg:ml-10 xl:ml-20 w-full lg:w-auto md:mx-10 lg:mx-0">
        <Link to="/mobile" className="text-3xl md:text-4xl lg:hidden">
          <FontAwesomeIcon icon={faBars} />
        </Link>
        <Link to="/" className="font-black hover:text-orange-600">
          Super Handy Home
        </Link>
      </div>
      <div className="hidden lg:flex text-white space-x-12 mr-10 lg:text-md xl:text-lg h-11 flex items-center">
        <HashLink
          to="/#About"
          className="hover:text-orange-600 hover:text-xl hover:font-black"
        >
          About
        </HashLink>
        <Link
          to="/plans"
          className="hover:text-orange-600 hover:text-xl hover:font-black"
        >
          Subscription Plans{' '}
        </Link>
        <HashLink
          to="/#Services"
          className="hover:text-orange-600 hover:text-xl hover:font-black"
        >
          Services
        </HashLink>
        <HashLink
          to="/#Contact Us"
          className="bg-orange-400 rounded-md h-9 px-10 flex items-center hover:bg-orange-600 hover:text-xl hover:font-black"
        >
          Contact Us
        </HashLink>
      </div>
    </div>
  );
}

export default Navbar;
