function Footer() {
  return (
    <div className="h-10 bg-orange-400 flex justify-center items-center lg:absolute lg:bottom-0 w-full">
      <p className="text-center text-white text-sm">
        2022 SUPER HANDY HOME. ALL RIGHTS RESERVED.
      </p>
    </div>
  );
}

export default Footer;
